<template>
  <div class="row m-0 mt-md-5 mb-md-5">
    <div class="col-md-3">
      <div class="card-header text-white pt-5 pb-5 h3 animate__animated animate__slideInDown">关于我们</div>
      <div class="list-group p-0">
        <a href="/company" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">中心简介</a>
        <a href="/leader" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown active">中心领导</a>
        <a href="/funcDep" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">职能部门</a>
        <a href="/leadership" class="bg-light text-dark list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">领导讲话</a>
      </div>
    </div>
    <div class="col-md-9 animate__animated animate__slideInUp">
      <div class="text-left h3">中心领导</div>
      <div class="text-left small">
        <span>2021年03月25日</span><span class="pl-md-3 pr-md-3"> / </span><span>Liych</span>
        <span class="pl-md-3 pr-md-3"> / </span><span><i class="fa fa-eye" aria-hidden="true"> 209</i></span>
      </div>
      <div class="text-left mt-md-5 w-100">
        <span class="pl-5">2021年6月18日中国管理科学研究院艺术发展中心在北京成立。中国管理科学研究院艺术发展中心，经中国管理科学研究院院长办公会议研究决定成立，是中国管理科学研究院直接领导和管理的国家事业单位。 </span>
<p />
        <span class="h3">成立宗旨</span>
        <p />
        <span class="pl-5">中国管理科学研究院艺术发展中心主要承担在文化艺术的普及、发展等方面的科学管理、研究及推广工作。从事文化、美术、音乐、影视等领域的艺术研究、设计、创作、研发、展览、演出、项目策划、经纪和社会艺术考级等相关活动，开展相关专业艺术家的培养培训和科学管理，组织理论研究、学术交流、讲座论坛、艺术创作、刊物出版和国际艺术交流，配合各地政府及部门合作，逐步建成一个学术体系开放、艺术成果共享的战略性艺术发展研究机构。
          </span>
        <p />
        机构设立：
        <p />
        领导班子成员：
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "leader"
}
</script>

<style scoped>
.div-center {
  margin: 0 auto;
}
</style>